import React from 'react'
import PropTypes from 'prop-types'

import TracklineBackground from '@objects/tracklineBackground'
import useStaticModule from '@hooks/useStaticModule'
import Container from '@objects/container'
import Copy from '@objects/copy'
import ContactForm from '@components/forms/contactForm/index'

function BarriereMeldenPage({ data, pageContext }) {
  const { getStaticFieldValue } = useStaticModule(pageContext.modules)

  const content = getStaticFieldValue(
    'accessibility.report',
    'accessibility.report.copy'
  )

  const infotexts = {
    type: 'accessibility',
    url: getStaticFieldValue(
      'accessibility.report',
      'accessibility.report.url.info'
    ),
    description: getStaticFieldValue(
      'accessibility.report',
      'accessibility.report.description.info'
    ),
    email: getStaticFieldValue(
      'accessibility.report',
      'accessibility.report.email.info'
    ),
    terms: getStaticFieldValue(
      'accessibility.report',
      'accessibility.report.privacy.info'
    ),
    thankyou: getStaticFieldValue(
      'accessibility.report',
      'accessibility.report.thankyou'
    ),
    error: getStaticFieldValue(
      'accessibility.report',
      'accessibility.report.error'
    ),
  }

  return (
    <Container role="region" aria-label="BarriereMeldenPage">
      <TracklineBackground track={2} bottom={'30%'} />
      <TracklineBackground track={3} right mirror top={'0%'} />

      <Copy className="lg:w-10/12 lg:mx-auto" html={content} div />
      <ContactForm
        className="mt-9 lg:w-10/12 lg:mx-auto"
        infotexts={infotexts}
      />
    </Container>
  )
}

BarriereMeldenPage.propTypes = {
  data: PropTypes.any,
  pageContext: PropTypes.object,
}

export default BarriereMeldenPage
